import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

import Logo from "./logo";
import LanguageSwitcher from "./languageSwitcher"
import phoneIcon from '../images/phone-b.svg';
import envelopeIcon from '../images/envelope-b.svg';
import fbIcon from '../images/f-b.svg';
import instaIcon from '../images/insta.svg';

const toggleMenu = (event) => { 
	event.preventDefault();
	const documentRoot = document.querySelector("html");

	documentRoot.classList.toggle("menu-active");
}
const offMenu = () => { 
	
	const documentRoot = document.querySelector("html");

	documentRoot.classList.remove("menu-active");
}

const NavMain = ({ intl }) => (
	<div className="nav-wrap">
		<div className="top_bar">
			<ul className="top_bar-contact">
				<li><span>DTT RNAAT 86/2014</span></li>
				{/* <li><a href="tel:+351910033399"><img className="icon" src={phoneIcon} /><span>+351 910 033 399</span></a></li> */}
				<li><a href="mailto:dragon.tree.travel@gmail.com"><img className="icon" src={envelopeIcon} /><span>dragon.tree.travel@gmail.com</span></a></li>
				<li><a href="https://www.facebook.com/Dragon.Tree.Travel"><img className="icon" src={fbIcon} /></a></li>
				<li><a href="https://www.instagram.com/madera_dragon.tree.travel/"><img className="icon" src={instaIcon} /></a></li>

				{/* <li><LanguageSwitcher/></li> */}
			</ul>
		</div>
		<nav className="nav--main">
			<Link to="/" style={{ display: `block`, maxWidth: `180px`, marginBottom: `1.45rem` }} id="logo" onClick={offMenu}>
					<Logo/>
				</Link>
			<button id="toggleMainNav" className="btn fatLink" type="button" onClick={toggleMenu}>Menu</button>
				
				<ul className="nav-items" onClick={toggleMenu}>
					<li>
						<Link to="/about/">{intl.formatMessage({ id: "navMain.about" })}</Link>
						<ul className="nav-items nav-items--sub">
							<li><Link to="/about/#team">{intl.formatMessage({ id: "navMain.about-team" })}</Link></li>
							<li><Link to="/about/#fleet">{intl.formatMessage({ id: "navMain.about-fleet" })}</Link></li>
							<li><Link to="/jeepsafari/#works">{intl.formatMessage({ id: "navMain.jeep_safari-works" })}</Link></li>
							<li><Link to="/jeepsafari/#practical">{intl.formatMessage({ id: "navMain.jeep_safari-practical" })}</Link></li>
							<li><Link to="/gallery/">{intl.formatMessage({ id: "navMain.gallery" })}</Link></li>
						</ul>
					</li>
					<li>
						<Link to="/jeepsafari/">{intl.formatMessage({ id: "navMain.jeep_safari" })}</Link>
						<ul className="nav-items nav-items--sub">
							<li><Link to="/jeepsafari/#trip1">{intl.formatMessage({ id: "navMain.trip1" })}</Link></li>
							<li><Link to="/jeepsafari/#trip2">{intl.formatMessage({ id: "navMain.trip2" })}</Link></li>
							<li><Link to="/jeepsafari/#trip3">{intl.formatMessage({ id: "navMain.trip3" })}</Link></li>
							<li><Link to="/jeepsafari/#trip4">{intl.formatMessage({ id: "navMain.trip4" })}</Link></li>
							{/* <li><Link to="/jeepsafari/#works">{intl.formatMessage({ id: "navMain.jeep_safari-works" })}</Link></li>
							<li><Link to="/jeepsafari/#practical">{intl.formatMessage({ id: "navMain.jeep_safari-practical" })}</Link></li> */}
						</ul>
					</li>
					<li>
						<Link to="/trekking/">{intl.formatMessage({ id: "navMain.trekking" })}</Link>
						<ul className="nav-items nav-items--sub">
							<li><Link to="/trekking/#trek-details">Pico do Arieiro</Link></li>
							<li><Link to="/trekking/#trek-details">RABAÇAL</Link></li>
							<li><Link to="/trekking/#trek-details">Fanal</Link></li>
							
						</ul>
					</li>
				</ul>
				<ul className="nav-items" onClick={toggleMenu}>
					<li>
						<Link to="/book/">{intl.formatMessage({ id: "navMain.book" })}</Link>
					</li>
					<li>
						<Link to="/contact/">{intl.formatMessage({ id: "navMain.contact" })}</Link>
					</li>
					<li>
						<Link to="/for-groups/">{intl.formatMessage({ id: "navMain.groups" })}</Link>
					</li>
				</ul>
			</nav>
		</div>
)

export default injectIntl(NavMain);